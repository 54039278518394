import { FRONT_JOBS } from '@/constants/Routes'
import useQueryFilters from '@/hooks/useQueryFilters'
import { Job } from '@/models/job/Job'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { faBriefcase, faGraduationCap } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosError } from 'axios'
import { useMemo, useState } from 'react'
import useSWR from 'swr'
import ListFetchingHandling from '../shared/dataFetching/ListFetchingHandling'
import FilterTextSearch from '../shared/filters/FilterTextSearch'

function partition(arr: Job[], fn: (x: Job) => boolean) {
  return arr.reduce(
    (acc, x) => {
      acc[fn(x) ? 0 : 1].push(x)
      return acc
    },
    [[], []] as [Job[], Job[]]
  )
}

const JobsTable = ({ data }: { data: Job[] }) => {
  return (
    <div className='wrapper'>
      <div className='table-grid'>
        {data.map(job => (
          <div className='table-grid-row _4-col' key={job.id}>
            {job.title && (
              <div className='table-column vertical'>
                <div>
                  <div className='table-item-text bold'>
                    <strong>{job.title}</strong>
                  </div>
                </div>
              </div>
            )}
            {job.companyName && (
              <div className='table-column'>
                <div className='fa-l small'></div>
                <div className='table-item-text'>{job.companyName}</div>
              </div>
            )}
            {job.companyType && (
              <div className='table-column'>
                <div className='color-primary'>
                  <div className='tag outlined small'>{job.companyType}</div>
                </div>
              </div>
            )}
            {job.URL && (
              <div className='table-column align-right'>
                <a
                  href={job.URL}
                  className='button small icon button-dark w-inline-block'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <div className='button-icon-content'>
                    <div className='button-icon-text'>More info</div>
                    <div className='button-icon small'></div>
                  </div>
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
const JobList = () => {
  const [queryFilters] = useQueryFilters('')
  const [queryText, setQueryText] = useState<string>('')

  const { data, error } = useSWR<{ data: Job[]; items: number }, AxiosError>(
    FRONT_JOBS(queryFilters, queryText),
    baseFetcher,
    { revalidateOnFocus: false }
  )

  const [student, notStudent] = useMemo(
    () => partition(data?.data ?? [], x => x.isStudentJob),
    [data]
  )

  return (
    <>
      <div className='margin-bottom-4x wf-section'>
        <div className='filters-block'>
          <div className='filters' />
          <FilterTextSearch searchText={setQueryText} />
        </div>
      </div>
      <ListFetchingHandling data={data} error={error} />
      {student.length > 0 && (
        <>
          <h4 className='margin-bottom-2x wrapper'>
            <FontAwesomeIcon icon={faGraduationCap} className='icon margin-right-1x' />
            Student Jobs
          </h4>
          <JobsTable data={student} />
          {notStudent.length > 0 && (
            <h4 className='margin-bottom-2x margin-top-2x wrapper'>
              <FontAwesomeIcon icon={faBriefcase} className='icon margin-right-1x' />
              Jobs
            </h4>
          )}
        </>
      )}
      <JobsTable data={notStudent} />
    </>
  )
}

export default JobList
