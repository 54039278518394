import { PublicJob } from '@/models/job/PublicJob'
import { Button, HStack, Text, useBoolean, useTheme, VStack } from '@chakra-ui/react'
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'

interface Props {
  job: PublicJob
  manual: boolean
  onToggle: (job: PublicJob, manual: boolean) => Promise<void>
  loadingOverride: boolean
}

const PortalJobItem = ({ job, manual, onToggle, loadingOverride }: Props) => {
  const theme = useTheme()
  const [loading, setLoading] = useBoolean()

  return (
    <VStack w='full' align='start' background={theme.colors.white} p={5}>
      <HStack w='full' justify='space-between'>
        <HStack>
          <FontAwesomeIcon icon={job.hidden ? faEyeSlash : faEye} />
          <Text>
            {job.title}
            {job.isStudentJob ? ' (student job)' : ''}
          </Text>
        </HStack>
        <Button
          colorScheme={!manual && job.hidden ? 'green' : 'red'}
          variant='outline'
          onClick={async () => {
            setLoading.on()
            await onToggle(job, manual)
            setLoading.off()
          }}
          isLoading={loading || loadingOverride}>
          {manual ? 'Delete' : job.hidden ? 'Show' : 'Hide'}
        </Button>
      </HStack>
      <Link href={job.URL || ''} passHref>
        <Button size='xs' variant='link'>
          {job.URL}
        </Button>
      </Link>
    </VStack>
  )
}

// noinspection JSUnusedGlobalSymbols
export default PortalJobItem
