// noinspection JSUnusedGlobalSymbols

export const JOB_TITLE = 9001
export const JOB_URL = 9002
export const JOB_TYPE = 9003
export const JOB_IS_ARCHIVED = 9005
export const JOB_IS_HIDDEN = 9006
export const JOB_IS_STUDENT = 9007
export const JOB_CREATED = 9095

export const JOB_TYPE_NUMBER_VALUES = { manual: 5459, extracted: 5458 }
export const JOB_TYPE_STRING_VALUES = { manual: 'manuel', extracted: 'extracté' }
